/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./index.vue?vue&type=template&id=0b882f4f&scoped=true"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=0b882f4f&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b882f4f",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Loading: require('/code/src/components/Loading.vue').default,CoverImage: require('/code/src/components/img/CoverImage.vue').default,ContentArea: require('/code/src/components/ContentArea.vue').default,TextButton: require('/code/src/components/button/TextButton.vue').default,IconButton: require('/code/src/components/button/IconButton.vue').default})
