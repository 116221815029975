import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';

var render = function render() {
  var _vm$lp, _vm$lp2, _vm$lp3;
  var _vm = this,
    _c = _vm._self._c;
  return _c(VContainer, {
    staticClass: "fill-height justify-center align-content-center flex-column",
    attrs: {
      "fluid": ""
    }
  }, [!_vm.lp ? _c('Loading') : [_c('ContentArea', {
    staticClass: "content-area",
    class: [_vm.$vuetify.breakpoint.smAndUp ? 'pa-8' : 'pa-4']
  }, [_c('div', {
    staticClass: "ma-auto",
    staticStyle: {
      "max-width": "476px"
    }
  }, [_c(VImg, {
    staticClass: "ma-auto",
    attrs: {
      "src": (_vm$lp = _vm.lp) === null || _vm$lp === void 0 ? void 0 : _vm$lp.logo,
      "height": "auto",
      "width": "150px"
    }
  }), _vm._v(" "), _c('h5', {
    staticClass: "mt-4 mb-6 text-center"
  }, [_vm._v("\n          " + _vm._s(
  // Note: senderが設定されていないことはないはずだがフェールセーフ入れておく
  (_vm.lp.sender ? _vm.lp.sender + _vm.$t('from') : '') + _vm.$t('wow.receivedGiftMessage')) + "\n        ")]), _vm._v(" "), _c(VCard, {
    staticClass: "mb-6",
    attrs: {
      "outlined": ""
    }
  }, [_c('div', {
    staticClass: "d-flex overflow-hidden"
  }, [_c('CoverImage', {
    staticClass: "tw-flex-shrink !tw-w-[150px] !tw-min-w-[120px]",
    attrs: {
      "src": _vm.lp.image
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "text-subtitle-1 align-self-center",
    class: [_vm.$vuetify.breakpoint.smAndUp ? 'pa-6' : 'pa-3']
  }, [_vm._v("\n              " + _vm._s(_vm.lp.product_name) + "\n            ")])], 1)]), _vm._v(" "), _c('div', {
    staticClass: "rounded color-background pa-4 text-body-1 d-flex flex-column align-center mx-auto"
  }, [_c('pre', {
    staticClass: "d-inline-block",
    staticStyle: {
      "white-space": "pre-wrap"
    }
  }, [_vm._v(_vm._s(_vm.lp.message))]), _vm._v(" "), _vm.lp.cta_link && _vm.lp.cta_text ? _c('a', {
    staticClass: "tw-btn tw-btn-outline-primary mt-4",
    staticStyle: {
      "max-width": "100%"
    },
    attrs: {
      "href": _vm.lp.cta_link,
      "target": "_blank",
      "rel": "noopener noreferrer"
    }
  }, [_c('span', {
    staticClass: "tw-text-center"
  }, [_vm._v("\n              " + _vm._s(_vm.lp.cta_text) + "\n            ")])]) : _vm._e()]), _vm._v(" "), !((_vm$lp2 = _vm.lp) !== null && _vm$lp2 !== void 0 && _vm$lp2.status) || _vm.lp.status === 'generated' ? _c('div', {
    staticClass: "d-flex justify-center mt-6"
  }, [_c('a', {
    staticClass: "tw-btn tw-btn-fill-primary",
    attrs: {
      "href": "/link/gift/".concat(_vm.$route.params.id)
    },
    domProps: {
      "textContent": _vm._s(_vm.$t('wow.receive'))
    }
  })]) : _c('div', {
    staticClass: "text-center"
  }, [_c(VDivider, {
    staticClass: "my-6"
  }), _vm._v(" "), _c('h5', [_vm._v(_vm._s(_vm.$t('wow.giftStatus.title')))]), _vm._v(" "), _c('div', {
    staticClass: "text-body-1 d-inline-flex mt-2",
    class: {
      'success--text': _vm.lp.status !== 'expired',
      'error--text': _vm.lp.status === 'expired'
    }
  }, [_c(VIcon, {
    staticClass: "mr-2",
    attrs: {
      "size": "20",
      "color": _vm.lp.status === 'expired' ? 'error' : 'success'
    }
  }, [_vm._v("\n              mdi-check-circle\n            ")]), _vm._v("\n            " + _vm._s(_vm.giftStatus) + "\n          ")], 1)], 1)], 1)]), _vm._v(" "), !((_vm$lp3 = _vm.lp) !== null && _vm$lp3 !== void 0 && _vm$lp3.status) ? _c('div', {
    staticClass: "d-flex justify-center"
  }, [_c('TextButton', {
    attrs: {
      "value": _vm.$t('wow.donate'),
      "disabled": _vm.loading
    },
    on: {
      "click": _vm.donate
    }
  }), _vm._v(" "), _c('IconButton', {
    attrs: {
      "icon": "mdi-help-circle"
    },
    on: {
      "click": function click($event) {
        return _vm.$open('https://sendwow.notion.site/f9c2b24683e54b0f8a027aced0b5959d');
      }
    }
  })], 1) : _vm._e()]], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };